<template>
<!-- 日间照料核心服务添加 -->
	<div class="wrapper">
		<vxe-toolbar>
			<template v-slot:buttons>
				<vxe-button status="my-orange" icon="fa fa-plus" @click="goPage('add')">添加</vxe-button>
			</template>
		</vxe-toolbar>
		<div class="table">
			<vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
				<template v-slot:apptype="{ row }">
					{{ row.appShowType==1?'上门服务护理员':(row.appShowType==2?'空巢独居从业人员':(row.appShowType==3?'志愿者':(row.appShowType==4?'商家从业人员':(row.appShowType==5?'养老顾问':'其他'))))}}
					<!-- <vxe-button type="text" status="primary" @click="goPage('add')">添加</vxe-button> -->
				</template>
				<template v-slot:operation="{ row }">
					<vxe-button type="text" status="primary" @click="goPage('edit', row)">修改</vxe-button>
					<vxe-button type="text" status="primary" @click="remove(row.id)">删除</vxe-button>
					<!-- <vxe-button type="text" status="primary" @click="goPage('add')">添加</vxe-button> -->
				</template>
			</vxe-grid>
			<vxe-pager :current-page.sync="tablePage.currentPage" :page-size.sync="tablePage.pageSize" :page-sizes="tablePage.pageSizes"
			 :total="tablePage.totalResult" :layouts="layouts" @page-change="pageChange"></vxe-pager>
		</div>
		    <el-dialog title="核心服务" :visible.sync="showCoreServe" width="35%" :close-on-click-modal="false">
      <el-form ref="form" :rules="coreServeRules" :model="form" label-width="120px">
        <el-form-item label="服务分类" prop="serviceType">
			<el-select v-model="form.serviceType" placeholder="请选择" style="width:100%;">
              <el-option
                v-for="item in serviceTypeList"
                :key="item.dictPidVal"
                :label="item.dictDesc"
                :value="item.dictPidVal">
              </el-option>
            </el-select>
          <!-- <el-input v-model="form.serviceType" placeholder="请输入服务分类" auto-complete="off"></el-input> -->
        </el-form-item>
        <el-form-item label="服务项目" prop="serviceName">
          <el-input v-model="form.serviceName" autocomplete="off" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="服务内容介绍" prop="serviceContent">
          <el-input type="textarea" :rows="2" v-model="form.serviceContent" placeholder="请输入"></el-input>
        </el-form-item>
          <el-form-item label="App适用范围" prop="appShowType">
          <el-select v-model="form.appShowType" placeholder="请选择" style="width:100%;">
              <el-option
                v-for="item in appTypeList"
                :key="item.dictPidVal"
                :label="item.dictDesc"
                :value="item.dictPidVal">
              </el-option>
            </el-select>
          <!-- <el-input v-model="form.appShowType" autocomplete="off" placeholder="请输入" type="number"></el-input> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel('form')">取 消</el-button>
        <el-button type="primary" @click="submitEvent('form')">确 定</el-button>
      </div>
    </el-dialog>
	</div>
</template>

<script>
	import {
		mapState,
		mapGetters,
		mapActions,
    mapMutations
	} from "vuex";
	import api from '@/store/API/api'
	export default {
		data() {
			return {
				loading: false,
				seachStatus: false,
				showCoreServe:false,
				searchData: {},
				serviceTypeList:[],
				// 分页的参数配置
				tablePage: {
					totalResult: 0,
					currentPage: 1,
					pageSize: 10,
					align: "left",
					pageSizes: [10, 20, 50, 100, 500, 1000],
					perfect: true
				},
				appTypeList : [
          {"dictPidVal": "1", "dictDesc": "上门服务护理员"},
          {"dictPidVal": "2", "dictDesc": "空巢独居从业人员"},
          {"dictPidVal": "3", "dictDesc": "志愿者"},
          {"dictPidVal": "4", "dictDesc": "商家从业人员"},
          {"dictPidVal": "5", "dictDesc": "养老顾问"},
          {"dictPidVal": "6", "dictDesc": "适老化评估员"},
          {"dictPidVal": "7", "dictDesc": "适老化施工员"},
          {"dictPidVal": "8", "dictDesc": "适老化验收员"},
          {"dictPidVal": "9", "dictDesc": "巡视探访人员"},
          {"dictPidVal": "10", "dictDesc": "养老顾问"},
				],
				btnType:'',
				tableColumn: [{
						type: 'seq',
						title: '序号',
						width: 100,
						align: "center"
					},
					{
						field: 'serviceName',
						title: '服务项目',
						showOverflow: true,
						align: "center",
						minWidth: "120"
					},
					{
						field: 'serviceTypeName',
						title: '服务分类',
						showOverflow: true,
						align: "center",
						minWidth: "120"
					},
					{
						field: 'serviceContent',
						title: '服务内容介绍',
						showOverflow: true,
						align: "center",
						minWidth: "100"
					},
					{
						field: 'appShowType',
						title: 'app适用范围',
						showOverflow: true,
						align: "center",
						minWidth: "100",
						slots:{
							default:'apptype'
						}
					},
					{
						title: '操作',
						width: 200,
						showOverflow: true,
						slots: {
							default: 'operation'
						},
					}
				],
				tableData: [],
				form:{
					serviceName:'',
					serviceType:'',
					serviceContent:'',
					appShowType:'',
					editId:null
				},
				coreServeRules: {
					serviceType: [
						{ required: true, message: '请输入服务分类', trigger: 'blur' }
					],
					serviceName: [
						{ required: true, message: '请输入服务项目', trigger: 'blur' }
					],
					serviceContent: [{ required: true, message: '请输入服务内容介绍', trigger: 'blur' }],
					appShowType: [{ required: true, message: '请选择app适用范围', trigger: 'blur' }],

      },
			}
		},
		computed: {
			...mapState([
				"layouts"
			]),
			...mapGetters(['seqId'])
		},
		watch: {
			seqId() {
				// this.getLists()
			}
		},
		created() {
			this.initView()
    		this.getSysDictList('cmCoreServiceType', 'serviceTypeList') //服务分类

		},
		methods: {
			...mapActions([
				"getCnCoreSeriveList",
				"deleteCnCoreSerive",
				'addOrSaveCnCoreSerive',
				"getSysDictServe"
			]),

			initView() {
				// this.getTeamList();
				// this.getCategoryList();
				this.getLists();
			},
			getSysDictList(code, codeList) {
				let params = {
					typeCode: code
				}
				this.getSysDictServe(params).then(res => {
					if(res.code == 200) {
					this[codeList] = res.data
					}
				})
				},
				cancel(formName){
					this.showCoreServe = false
					this.$refs[formName].resetFields();
				},
			goPage(type, itemData) {
				this.btnType = type
				this.showCoreServe = true
				console.log(type,itemData);
				if(type=='edit'){
					console.log(itemData);
					this.form.serviceName = itemData.serviceName
					this.form.serviceType = itemData.serviceType
					this.form.serviceContent = itemData.serviceContent
					this.form.appShowType = String(itemData.appShowType)
					this.form.editId = itemData.id

				}
				// this.$router.push({
				// 	name: 'AddCnCoreSerive',
				// 	params: {
				// 		type: type,
				// 		id: type == 'add' ? 'add' : itemData.id,
				// 		compId: this.$route.params.id
				// 	},
				// 	query: {
				// 		item: type == 'add' ? 'add' : JSON.stringify(itemData),
				// 		source: this.$route.query.type,
				// 	}
				// })
			},

			//添加/修改核心服务按钮
			submitEvent(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let source = null
						if (this.$route.query.type == 'serviceStation') {
							source = 11
						}
						if (this.$route.query.type == 'sumCenter') {
							source = 12
						}
						if (this.$route.query.type== 'generalServiceCenter') {
							source = 13
						}
						if (this.$route.query.type == 'mktCompany') {
							source = 15
						}

						let params = {
							serviceName:this.form.serviceName,
							serviceType:this.form.serviceType,
							serviceContent:this.form.serviceContent,
							appShowType:this.form.appShowType,
							compId: this.$route.params.id,
							id:this.form.editId,
							source,
						}
						let server = 'addOrSaveCnCoreSerive'
						this[server](params).then((res) => {
							if (res.code == 200) {
								this.$XModal.message({
									message: this.btnType == 'add' ? '添加成功' : '修改成功',
									status: 'success',
								})
								this.showCoreServe = false
								this.$refs[formName].resetFields();
								this.getLists();
								// this.$router.go(-1)
							}
							this.loading = false
						})
					} else {
						return false
					}
				})
    },


			getLists() {
				this.loading = true;
				this.getCnCoreSeriveList({
					current: this.tablePage.currentPage,
					size: this.tablePage.pageSize,
					param: {
						compId: this.$route.params.id
					}
				}).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.records;
						// console.log(this.tableData );
						// this.tableData.map(item=>{
						// 	if(item.appShowType=='1'){
						// 		item.appShowType = '上门服务护理员'
						// 	}else if(item.appShowType=='2'){
						// 		item.appShowType = '空巢独居从业人员'
						// 	}else if(item.appShowType=='3'){
						// 		item.appShowType = '志愿者'
						// 	}else if(item.appShowType=='4'){
						// 		item.appShowType = '商家从业人员'
						// 	}else if(item.appShowType=='5'){
						// 		item.appShowType = '养老顾问'
						// 	}
						// })
						this.tablePage.totalResult = +res.data.total;
					}
					this.loading = false;
				})
			},
			// 分页功能
			pageChange(item) {
				if (item.type == "size") {
					this.tablePage.currentPage = 1;
				} else {
					this.tablePage.currentPage = item.currentPage;
				}
				this.getLists();
			},
			searchEvent() {
				console.log(this.searchData);
				this.seachStatus = false;
				this.getLists();
			},
			searchReset() {
				this.searchData = {};
				this.getLists();
			},
			remove(id) {
				this.$XModal.confirm("您确定要删除该数据?").then(type => {
					if (type === "confirm") {
						console.log('执行删除操作');
						this.delete(id)
					}
				})
			},
			delete(id) {
				this.deleteCnCoreSerive({
					id: id
				}).then(res => {
					if (res.code == 200) {
						this.$XModal.message({
							message: '删除成功',
							status: "success"
						});
						this.getLists()
					}
					this.loading = false;
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
    .table{
		overflow-y:auto;
	}
	.wrapper{
	  .datetime{
	    display: flex;
	    align-items: center;
	    span{
	      margin: 0 3px;
	    }
	  }
	}
	.sign i {
		margin-right: 3px;
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
	}
</style>
