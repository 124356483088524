<template>
  <div class="wrapper">
    <div class="table">
      <vxe-grid border resizable show-header-overflow show-overflow highlight-hover-row :columns="tableColumn" :data="tableData" :loading="loading">
        <template v-slot:name="{ row }">
          <p class="link-a" @click="toDetail('detail', row)">{{ row.name }}</p>
        </template>
      </vxe-grid>
      <vxe-pager
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult"
        :layouts="layouts"
        @page-change="pageChange"
      ></vxe-pager>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      loading: false,
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 100,
          align: 'center',
        },
        {
          field: 'name',
          title: '姓名',
          showOverflow: true,
          align: 'center',
          width: '90',
          slots: {
            default: 'name',
          },
        },
        {
          field: 'sex',
          title: '性别',
          showOverflow: true,
          align: 'center',
          width: '60',
        },
        {
          field: 'age',
          title: '年龄',
          showOverflow: true,
          align: 'center',
          width: '60',
        },
        {
          field: 'idNum',
          title: '身份证号',
          showOverflow: true,
          align: 'center',
          width: '180',
        },
        {
          field: 'usualAddress',
          title: '住址',
          showOverflow: true,
          align: 'center',
          width: '300',
        },
        {
          field: 'contract',
          title: '联系方式',
          showOverflow: true,
          align: 'center',
          width: '180',
        },
        {
          field: 'inPensOrgBeginTime',
          title: '入住时间',
          showOverflow: true,
          align: 'center',
          width: '120',
        },
        {
          field: 'inPensOrgEndTime',
          title: '离院时间',
          showOverflow: true,
          align: 'center',
          width: '120',
        },
      ],
      tableData: [],
    }
  },
  computed: {
    ...mapState(['layouts']),
  },
  watch: {},
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['getHomePersonNewListServe', 'delorgPerson']),
    initView() {
      this.getLists()
    },
    getLists() {
      this.loading = true
      this.getHomePersonNewListServe({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          inPensOrgId: this.$route.params.id,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records.map((item) => {
            return {
              ...item,
              certNameList: item.certNameList && item.certNameList.lenght > 0 ? item.certNameList.split(',') : '无',
            }
          })
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    goPage(type, itemData) {
      this.$router.push({
        name: 'AddAged',
        params: {
          type: type,
          id: type == 'add' ? 'add' : this.$route.params.id,
        },
        query: {
          item: JSON.stringify(itemData),
        },
      })
    },
    toDetail(type, itemData) {
      this.$router.push({
        name: 'AddPerson',
        params: {
          type: type,
          id: itemData.id,
        },
      })
    },
    remove(id) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          console.log('执行删除操作')
          this.delete(id)
        }
      })
    },
    delete(id) {
      this.delorgPerson({
        id: id,
      }).then((res) => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success',
          })
          this.getLists()
        }
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
