<template>
  <div class="wrapper">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="基本信息" name="first">
        <add-or-edit />
        <!--<base-info />-->
      </el-tab-pane>
      <el-tab-pane label="资产登记" name="eleven">
        <assets-record v-if="activeName === 'eleven'" v-permission="'SumCenterDetailElevenTab'" />
      </el-tab-pane>
      <el-tab-pane label="员工信息" name="second">
        <correspondence-list v-if="activeName === 'second'" v-permission="'SumCenterDetailSecondTab'" />
      </el-tab-pane>
      <el-tab-pane label="消杀记录" name="twelve">
        <cleaning-record v-if="activeName === 'twelve'" v-permission="'SumCenterDetailTwelveTab'" :service-org-id-prop="serviceOrgId" />
      </el-tab-pane>
      <!-- <el-tab-pane label="消毒" name="thirteen">
        <disinfect-record v-if="isdisinfect"></disinfect-record>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="消杀记录" name="thirteen">
        <disinfect-record v-if="isdisinfect"></disinfect-record>
      </el-tab-pane> -->
      <el-tab-pane label="核心服务" name="third">
        <cn-core-service v-if="activeName === 'third'" v-permission="'SumCenterDetailThirdTab'" />
      </el-tab-pane>
      <el-tab-pane label="服务记录" name="fourth">
        <serviceRecord v-if="activeName === 'fourth'" v-permission="'SumCenterDetailFourthTab'" :service-org-id-prop="serviceOrgId" />
      </el-tab-pane>
      <el-tab-pane label="服务对象" name="five">
        <service-object v-if="activeName === 'five'" v-permission="'SumCenterDetailFiveTab'" />
      </el-tab-pane>
      <el-tab-pane label="入住老人" name="six">
        <resident-elderly v-if="activeName === 'six'" v-permission="'SumCenterDetailSixTab'" />
      </el-tab-pane>
      <el-tab-pane label="老人签到" name="seven">
        <personSign v-if="activeName === 'seven'" v-permission="'SumCenterDetailSevenTab'" :person-sign-type="1" />
      </el-tab-pane>
      <el-tab-pane label="员工签到" name="eight">
        <personSign v-if="activeName === 'eight'" v-permission="'SumCenterDetailEightTab'" :person-sign-type="2" />
      </el-tab-pane>
      <el-tab-pane label="运营日报" name="nine">
        <dailyNewspaper v-if="activeName === 'nine'" v-permission="'SumCenterDetailNineTab'" />
      </el-tab-pane>
      <el-tab-pane label="活动记录" name="ten">
        <other-import-task v-if="activeName === 'ten'" v-permission="'SumCenterDetailTenTab'" :initiator-user-id="serviceOrgId" />
      </el-tab-pane>

    </el-tabs>
  </div>
</template>
<script>
import cnCoreService from '../cnCoreSerive/index.vue'
import serviceRecord from '../../../HomeCare/ServeRecords/list.vue'
import correspondenceList from '../correspondenceList/index.vue'
import serviceObject from '../serviceObject/index.vue'
import residentElderly from '../residentElderly/index.vue'
import personSign from '../personSign/index.vue'
import dailyNewspaper from '../dailyNewspaper/index.vue'
import assetsRecord from '../assetsRecord/index.vue'
import cleaningRecord from '../cleaningRecord/index.vue'
import AddOrEdit from './addOrEdit'
import OtherImportTask from '@/views/Pages/task/component/otherImportTask/index.vue'

export default {
  name: 'Detail',
  components: { OtherImportTask, AddOrEdit, cnCoreService, serviceRecord, correspondenceList, serviceObject, residentElderly, personSign, dailyNewspaper, assetsRecord, cleaningRecord },
  data() {
    return {
      activeName: 'first',
      serviceOrgId: (JSON.parse(this.$route.query.item)).id,
      isdisinfect: false

    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow-y: auto;

  .m-content {
    .mc-item {
      line-height: 30px;

      span {
        display: inline-block;
        width: 150px;
        text-align: right;
      }
    }
  }

  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}</style>
