<template>
  <div class="wrapper">
    <CustomForm ref="CustomForm" v-loading="loading" :form-model="formModel" :form-items="formItem" :form-rules="formRules">
      <UploadImg slot="businessLicense" slot-scope="{item}" :upload-lists="businessLicenseUploadList" :pic-disable="formStatus === 'detail'" @uploadChange="item.uploadChange" />
      <UploadImg slot="professionalQualification" slot-scope="{item}" :upload-lists="professionalQualificationUploadList" :pic-disable="formStatus === 'detail'" @uploadChange="item.uploadChange" />
      <UploadImg slot="imgs" slot-scope="{item}" :upload-lists="imgsUploadList" :limit="6" :pic-disable="formStatus === 'detail'" @uploadChange="item.uploadChange" />
      <RegionForm slot="tenant" ref="RegionForm" v-model="formModel.tenant" :disabled="formStatus === 'detail'" :multiple="true" />
      <CustomMap slot="customMap" ref="CustomMap" :lat="formModel.latitude" :lng="formModel.longitude" :disabled="formStatus === 'detail'" @callback="formItem.customMap.callback" />
      <div slot="detailAddress">
        <el-input
          v-model="formModel.detailAddress"
          type="text"
          :maxlength="255"
          placeholder="请输入详细地址或在地图中选择"
          clearable
          :disabled="formStatus === 'detail'"
        />
        <div style="display: flex; margin-top: 10px;">
          <el-input
            v-model="formModel.latitude"
            type="text"
            placeholder="请在地图中选择位置以获取纬度"
            disabled
            style="margin-right: 5px;"
          />
          <el-input
            v-model="formModel.longitude"
            type="text"
            placeholder="请在地图中选择位置以获取经度"
            disabled
            style="margin-left: 5px;"
          />
        </div>
      </div>
      <div slot="button" style="text-align: center;">
        <el-button v-if="formStatus !== 'detail'" type="primary" icon="fa fa-check" @click="handleSubmit">保存</el-button>
        <el-button icon="fa fa-undo" @click="back">返回</el-button>
      </div>
    </CustomForm>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import RegionForm from '@/components/RegionForm.vue'
import CustomForm from '@/components/CustomForm/index'
import CustomMap from '@/components/CustomMap/index'

export default {
  name: 'AddOrEdit',
  components: {
    CustomMap,
    CustomForm,
    UploadImg,
    RegionForm
  },
  data() {
    return {
      loading: true,
      businessLicenseUploadList: [],
      professionalQualificationUploadList: [],
      imgsUploadList: [],
      dict: {
        org_type: [],
        sunCenterStatus: [],
        nature: [
          { label: '政府机构', value: '政府机构' },
          { label: '盈利机构', value: '盈利机构' },
          { label: '非营利机构', value: '非营利机构' }
        ],
        facilityNature: [
          { value: '政府资产', label: '政府资产' },
          { value: '自有资产', label: '自有资产' }
        ],
        constructionSubject: [
          { value: '政府', label: '政府' },
          { value: '国企', label: '国企' },
          { value: '企业', label: '企业' }
        ],
        type: [
          { value: '公建民营', label: '公建民营' },
          { value: '公办民营', label: '公办民营' },
          { value: '公办公营', label: '公办公营' },
          { value: '民办', label: '民办' }
        ],
        venueSource: [
          { value: '自有产权', label: '自有产权' },
          { value: '政府产权', label: '政府产权' },
          { value: '国企产权', label: '国企产权' },
          { value: '租赁', label: '租赁' }
        ]
      },
      formModel: {
        name: '',
        mainPersonName: '',
        mainPersonTel: '',
        detailDesc: '',
        type: '公建民营',
        orgType: '',
        nature: '政府机构',
        facilityNature: '政府资产',
        venueSource: '自有产权',
        areaSquare: 0,
        constructionSubject: '政府',
        completionTime: '',
        constructionSubsidyAmount: 0,
        constructionSubsidyTime: '',
        operCompName: '',
        operatingSubsidyAmount: 0,
        workBeginTime: '',
        workEndTime: '',
        bedNumber: 0,
        fullCareBedNum: 0,
        nursingBedNum: 0,
        selfCareBedNum: 0,
        restBedNum: 0,
        inOldPersonCnt: 0,
        emptyBedNumber: '',
        businessLicense: '',
        professionalQualification: '',
        imgs: '',
        tenant: [],
        detailAddress: '',
        longitude: 0,
        latitude: 0,
        status: ''
      }
    }
  },
  computed: {
    formId() {
      return this.$route.params.id
    },
    formStatus() {
      return this.$route.params.type
    },
    formItem() {
      return {
        'name': {
          'elColSpan': 8,
          'component': 'CustomFormText',
          'label': '名称',
          'maxlength': 255,
          'disabled': this.formStatus === 'detail'
        },
        'mainPersonName': {
          'elColSpan': 8,
          'component': 'CustomFormText',
          'label': '联系人',
          'maxlength': 100,
          'disabled': this.formStatus === 'detail'
        },
        'mainPersonTel': {
          'elColSpan': 8,
          'component': 'CustomFormText',
          'label': '联系方式',
          'maxlength': 100,
          'disabled': this.formStatus === 'detail'
        },
        'detailDesc': {
          'elColSpan': 24,
          'component': 'CustomFormTextarea',
          'label': '简介',
          'maxlength': 1000,
          'showWordLimit': true,
          'disabled': this.formStatus === 'detail'
        },
        'type': {
          'elColSpan': 12,
          'component': 'CustomFormSelect',
          'label': '所属类型',
          'data': this.dict.type,
          'disabled': this.formStatus === 'detail'
        },
        'orgType': {
          'elColSpan': 12,
          'component': 'CustomFormSelect',
          'label': '类型',
          'dataField': {
            'label': 'dictDesc',
            'value': 'dictPidVal'
          },
          'data': this.dict.org_type,
          'disabled': this.formStatus === 'detail'
        },
        'nature': {
          'elColSpan': 12,
          'component': 'CustomFormSelect',
          'label': '性质',
          'data': this.dict.nature,
          'disabled': this.formStatus === 'detail'
        },
        'facilityNature': {
          'elColSpan': 12,
          'component': 'CustomFormSelect',
          'label': '设施性质',
          'data': this.dict.facilityNature,
          'disabled': this.formStatus === 'detail'
        },
        'venueSource': {
          'elColSpan': 12,
          'component': 'CustomFormSelect',
          'label': '场地来源',
          'data': this.dict.venueSource,
          'disabled': this.formStatus === 'detail'
        },
        'areaSquare': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '面积(平方米)',
          'min': 0,
          'max': 99999999,
          'precision': 2,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'constructionSubject': {
          'elColSpan': 12,
          'component': 'CustomFormSelect',
          'label': '建设主体',
          'data': this.dict.constructionSubject,
          'disabled': this.formStatus === 'detail'
        },
        'completionTime': {
          'elColSpan': 12,
          'component': 'CustomFormDatePicker',
          'label': '建成时间',
          'type': 'date',
          'valueFormat': 'yyyy-MM-dd',
          'disabled': this.formStatus === 'detail'
        },
        'constructionSubsidyAmount': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '建设补贴金额(万元)',
          'min': 0,
          'max': 99999999,
          'precision': 2,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'constructionSubsidyTime': {
          'elColSpan': 12,
          'component': 'CustomFormDatePicker',
          'label': '建设补贴时间',
          'type': 'date',
          'valueFormat': 'yyyy-MM-dd',
          'disabled': this.formStatus === 'detail'
        },
        'operCompName': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '运营企业',
          'maxlength': 255,
          'disabled': this.formStatus === 'detail'
        },
        'operatingSubsidyAmount': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '运营补贴金额(万元)',
          'min': 0,
          'max': 99999999,
          'precision': 2,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'workBeginTime': {
          'elColSpan': 12,
          'component': 'CustomFormDatePicker',
          'label': '开门时间',
          'type': 'date',
          'valueFormat': 'yyyy-MM-dd',
          'disabled': this.formStatus === 'detail'
        },
        'workEndTime': {
          'elColSpan': 12,
          'component': 'CustomFormDatePicker',
          'label': '关门时间',
          'type': 'date',
          'valueFormat': 'yyyy-MM-dd',
          'disabled': this.formStatus === 'detail'
        },
        'bedNumber': {
          'elColSpan': 24,
          'component': 'CustomFormNumber',
          'label': '床位数',
          'min': 0,
          'max': 99999999,
          'precision': 0,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'fullCareBedNum': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '全托床位数量',
          'min': 0,
          'max': 99999999,
          'precision': 0,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'nursingBedNum': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '护理型床位数量',
          'min': 0,
          'max': 99999999,
          'precision': 0,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'selfCareBedNum': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '自理型床位数量',
          'min': 0,
          'max': 99999999,
          'precision': 0,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'restBedNum': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '休息床位数量',
          'min': 0,
          'max': 99999999,
          'precision': 0,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'inOldPersonCnt': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '入住老人数量',
          'min': 0,
          'max': 99999999,
          'precision': 0,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'emptyBedNumber': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '空余床位数',
          'min': 0,
          'max': 99999999,
          'precision': 0,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'businessLicense': {
          'elColSpan': 12,
          'label': '营业执照',
          'slot': 'businessLicense',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'businessLicense', data[0])
            } else {
              this.$set(this.formModel, 'businessLicense', '')
            }
          }
        },
        'professionalQualification': {
          'elColSpan': 12,
          'label': '从业资质',
          'slot': 'professionalQualification',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'professionalQualification', data[0])
            } else {
              this.$set(this.formModel, 'professionalQualification', '')
            }
          }
        },
        'imgs': {
          'elColSpan': 24,
          'label': '图片',
          'slot': 'imgs',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'imgs', data.join(','))
            } else {
              this.$set(this.formModel, 'imgs', '')
            }
          }
        },
        'tenant': {
          'elColSpan': 24,
          'label': '服务区域',
          'slot': 'tenant'
        },
        'detailAddress': {
          'elColSpan': 24,
          'label': '地址信息',
          'slot': 'detailAddress'
        },
        'customMap': {
          'elColSpan': 24,
          'slot': 'customMap',
          'callback': data => {
            this.formModel.detailAddress = data.poiaddress
            this.formModel.latitude = data.latlng.lat
            this.formModel.longitude = data.latlng.lng
          }
        },
        'status': {
          'elColSpan': 24,
          'component': 'CustomFormRadio',
          'label': '状态',
          'dataField': {
            'text': 'dictDesc',
            'label': 'dictPidVal'
          },
          'data': this.dict.sunCenterStatus,
          'disabled': this.formStatus === 'detail'
        },
        'button': {
          'elColSpan': 24,
          'slot': 'button'
        }
      }
    },
    formRules() {
      return {
        name: [
          { required: true, message: '名称不能为空', trigger: ['blur', 'change'] }
        ],
        mainPersonName: [
          { required: true, message: '联系人不能为空', trigger: ['blur', 'change'] }
        ],
        mainPersonTel: [
          { required: true, message: '联系方式不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value && !this.$regex.telRegEx(value)) {
                callback(new Error('联系方式格式不正确'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        type: [
          { required: true, message: '所属类型不能为空', trigger: ['blur', 'change'] }
        ],
        orgType: [
          { required: true, message: '类型不能为空', trigger: ['blur', 'change'] }
        ],
        nature: [
          { required: true, message: '性质不能为空', trigger: ['blur', 'change'] }
        ],
        facilityNature: [
          { required: true, message: '设施性质不能为空', trigger: ['blur', 'change'] }
        ],
        venueSource: [
          { required: true, message: '场地来源不能为空', trigger: ['blur', 'change'] }
        ],
        areaSquare: [
          { required: true, message: '面积不能为空', trigger: ['blur', 'change'] }
        ],
        constructionSubject: [
          { required: true, message: '建设主体不能为空', trigger: ['blur', 'change'] }
        ],
        constructionSubsidyAmount: [
          { required: true, message: '建设补贴金额不能为空', trigger: ['blur', 'change'] }
        ],
        operatingSubsidyAmount: [
          { required: true, message: '运营补贴金额不能为空', trigger: ['blur', 'change'] }
        ],
        bedNumber: [
          { required: true, message: '床位数不能为空', trigger: ['blur', 'change'] }
        ],
        fullCareBedNum: [
          { required: true, message: '全托床位数量不能为空', trigger: ['blur', 'change'] }
        ],
        nursingBedNum: [
          { required: true, message: '护理型床位数量不能为空', trigger: ['blur', 'change'] }
        ],
        selfCareBedNum: [
          { required: true, message: '自理型床位数量不能为空', trigger: ['blur', 'change'] }
        ],
        restBedNum: [
          { required: true, message: '休息床位数量不能为空', trigger: ['blur', 'change'] }
        ],
        inOldPersonCnt: [
          { required: true, message: '入住老人数量不能为空', trigger: ['blur', 'change'] }
        ],
        emptyBedNumber: [
          { required: true, message: '空余床位数不能为空', trigger: ['blur', 'change'] }
        ],
        businessLicense: [
          { required: true, message: '营业执照不能为空', trigger: ['blur', 'change'] }
        ],
        professionalQualification: [
          { required: true, message: '从业资质不能为空', trigger: ['blur', 'change'] }
        ],
        tenant: [
          { required: true, message: '服务区域不能为空', trigger: ['blur', 'change'] }
        ],
        detailAddress: [
          { required: true, message: '地址信息不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (!this.formModel.latitude || !this.formModel.longitude) {
                callback(new Error('坐标信息不能为空'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        status: [
          { required: true, message: '状态不能为空', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  created() {
    this.getSysDictList('sunCenterStatus')
    this.getSysDictList('org_type')
    this.initData().then(() => {
      this.$nextTick(() => {
        this.$refs.CustomMap.init()
        this.$refs.RegionForm.initRegion()
      })
      this.$refs.CustomForm.$refs.form.clearValidate()
      this.closeLoading()
    }).catch(() => {
      this.back()
    })
  },
  methods: {
    ...mapActions(['downloadFile', 'getSysDictServe', 'detailSumCenter', 'addOrSaveSumCenter']),
    getSysDictList(typeCode) {
      this.getSysDictServe({ typeCode }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.dict[typeCode] = res.data
      })
    },
    closeLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    back() {
      this.$router.go(-1)
    },
    initData() {
      return new Promise((resolve, reject) => {
        if (this.formStatus !== 'add') {
          this.detailSumCenter({ id: this.formId }).then(res => {
            if (res.code !== 200) {
              this.$message.error(res.msg)
              reject()
              return
            }

            this.getFileDetail(res.data.businessLicense, 'businessLicenseUploadList')
            this.getFileDetail(res.data.professionalQualification, 'professionalQualificationUploadList')
            this.getFileDetail(res.data.imgs, 'imgsUploadList')

            this.formModel.id = this.formId
            this.formModel.name = res.data.name
            this.formModel.mainPersonName = res.data.mainPersonName
            this.formModel.mainPersonTel = res.data.mainPersonTel
            this.formModel.detailDesc = res.data.detailDesc
            this.formModel.type = res.data.type
            this.formModel.orgType = res.data.orgType
            this.formModel.nature = res.data.nature
            this.formModel.facilityNature = res.data.facilityNature
            this.formModel.venueSource = res.data.venueSource
            this.formModel.areaSquare = res.data.areaSquare
            this.formModel.constructionSubject = res.data.constructionSubject
            this.formModel.completionTime = res.data.completionTime
            this.formModel.constructionSubsidyAmount = res.data.constructionSubsidyAmount
            this.formModel.constructionSubsidyTime = res.data.constructionSubsidyTime
            this.formModel.operCompName = res.data.operCompName
            this.formModel.operatingSubsidyAmount = res.data.operatingSubsidyAmount
            this.formModel.workBeginTime = res.data.workBeginTime
            this.formModel.workEndTime = res.data.workEndTime
            this.formModel.bedNumber = res.data.bedNumber
            this.formModel.fullCareBedNum = res.data.fullCareBedNum
            this.formModel.nursingBedNum = res.data.nursingBedNum
            this.formModel.selfCareBedNum = res.data.selfCareBedNum
            this.formModel.restBedNum = res.data.restBedNum
            this.formModel.inOldPersonCnt = res.data.inOldPersonCnt
            this.formModel.emptyBedNumber = res.data.emptyBedNumber
            this.formModel.businessLicense = res.data.businessLicense
            this.formModel.professionalQualification = res.data.professionalQualification
            this.formModel.imgs = res.data.imgs
            this.formModel.tenant = res.data.tenants.split('|').map((item) => {
              return item.split(',').slice(2)
            })
            this.formModel.detailAddress = res.data.detailAddress
            this.formModel.longitude = res.data.longitude
            this.formModel.latitude = res.data.latitude
            this.formModel.status = res.data.status
            resolve()
          }).catch(() => {
            reject()
          })
        } else {
          resolve()
        }
      })
    },
    getFileDetail(ids, field) {
      if (!ids) {
        return
      }
      ids = ids.split(',')
      if (ids.length === 0) {
        return
      }
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this[field].push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    handleSubmit() {
      this.loading = true
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          const params = { ...this.formModel }
          params.tenant = params.tenant.map((item) => {
            return `1,101,${item.join(',')}`
          })

          if (this.formStatus !== 'detail') {
            this.addOrSaveSumCenter(params).then(res => {
              if (res.code !== 200) {
                this.closeLoading()
                this.$message.error(res.msg)
                return
              }
              this.$message.success(this.formStatus === 'add' ? '添加成功' : '修改成功')
              this.back()
            }).catch(() => {
              this.closeLoading()
            })
          } else {
            this.back()
          }
        } else {
          this.closeLoading()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    overflow: auto;
  }
</style>
